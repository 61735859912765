import {
	Fragments,
	getAnalytics,
	useRouter,
	initializeApollo,
	Queries,
	SegmentAnalytics,
	booleanFilter,
	WebTracking
} from 'common';
import {
	PlaybackTrackingParams,
	transformContentDocumentsToProductList,
	transformContentDocumentToProduct,
	transformCourseDocumentToProduct,
	transformToPlayback
} from './segmentTransformers';
import { ContentDocumentFields } from '../content';
import type { CourseTableItem } from '../../containers/MediathekHome/CoursesSection/components/CoursesTable';

// View Item List
// https://segment.com/docs/connections/destinations/catalog/actions-google-analytics-4/#view-item-list
export const contentListViewed = async (
	contentDocuments: Array<ContentDocumentFields | Fragments.ContentDocumentSearchFieldsFragment>,
	listId: string | undefined,
	locale: Locale
) => {
	if (contentDocuments.length > 0) {
		const analytics = await getAnalytics();

		const contentsForAnalytics = (
			await Promise.all(
				contentDocuments.map((contentDocument) =>
					getContentForAnalyticsById(contentDocument.contentId)
				)
			)
		).filter(booleanFilter);

		analytics?.web.productListViewed(
			transformContentDocumentsToProductList(contentsForAnalytics, listId, locale)
		);
	}
};

// Product Clicked
export const contentClicked = async (
	contentId: string,
	params: {
		locale: Locale;
		index: number | undefined;
	}
) => {
	const { content, analytics } = await getContentAndAnalytics(contentId);
	analytics.web.productClicked(transformContentDocumentToProduct(content, params));
};

// Product Clicked
export const courseClicked = async (
	course: CourseTableItem,
	params: {
		locale: Locale;
		index: number | undefined;
	}
) => {
	const { content, analytics } = await getContentAndAnalytics(course.segmentFields.contentId);
	analytics.web.productClicked(transformContentDocumentToProduct(content, params));
};

// Product Viewed
// https://segment.com/docs/connections/destinations/catalog/actions-google-analytics-4/#select-item
export const contentViewed = async (
	contentDocument:
		| ContentDocumentFields
		| Fragments.ContentDocumentSearchFieldsFragment
		| Fragments.ContentWithProgramSchedulesFieldsFragment,
	params: {
		locale: Locale;
		index: number | undefined;
	}
) => {
	const { content, analytics } = await getContentAndAnalytics(contentDocument.contentId);
	analytics.web.productViewed(transformContentDocumentToProduct(content, params));
};

export const videoThumbnailViewed = async (
	contentId: string,
	params: {
		locale: Locale;
		index: number | undefined;
	}
) => {
	const { content, analytics } = await getContentAndAnalytics(contentId);
	analytics.web.videoThumbnailViewed(transformContentDocumentToProduct(content, params));
};

export const documentThumbnailViewed = async (
	contentId: string,
	params: {
		locale: Locale;
		index: number | undefined;
	}
) => {
	const { content, analytics } = await getContentAndAnalytics(contentId);
	analytics.web.documentThumbnailViewed(transformContentDocumentToProduct(content, params));
};

export const videoStarted = async (
	videoParams: PlaybackTrackingParams,
	contentParams: {
		locale: Locale;
		index: number | undefined;
		contentId?: string;
		courseId?: string;
	}
) => {
	const { content, course, analytics } = await getContentOrCourseAndAnalytics(contentParams);
	const product = content
		? transformContentDocumentToProduct(content, contentParams)
		: transformCourseDocumentToProduct(course, contentParams);

	const video = transformToPlayback(videoParams, true);
	analytics.web.videoPlaybackStarted({
		...product,
		...video
	});
};

export const videoProgress = async (
	videoParams: PlaybackTrackingParams,
	contentParams: {
		locale: Locale;
		index: number | undefined;
		contentId?: string;
		courseId?: string;
	}
) => {
	const { content, course, analytics } = await getContentOrCourseAndAnalytics(contentParams);
	const product = content
		? transformContentDocumentToProduct(content, contentParams)
		: transformCourseDocumentToProduct(course, contentParams);

	const video = transformToPlayback(videoParams, false);
	analytics.web.videoPlaybackProgress({
		...product,
		...video
	});
};

export const videoPaused = async (
	videoParams: PlaybackTrackingParams,
	contentParams: {
		locale: Locale;
		index: number | undefined;
		contentId?: string;
		courseId?: string;
	}
) => {
	const { content, course, analytics } = await getContentOrCourseAndAnalytics(contentParams);
	const product = content
		? transformContentDocumentToProduct(content, contentParams)
		: transformCourseDocumentToProduct(course, contentParams);

	const video = transformToPlayback(videoParams, false);
	analytics.web.videoPlaybackPaused({
		...product,
		...video
	});
};

export const videoResumed = async (
	videoParams: PlaybackTrackingParams,
	contentParams: {
		locale: Locale;
		index: number | undefined;
		contentId?: string;
		courseId?: string;
	}
) => {
	const { content, course, analytics } = await getContentOrCourseAndAnalytics(contentParams);
	const product = content
		? transformContentDocumentToProduct(content, contentParams)
		: transformCourseDocumentToProduct(course, contentParams);

	const video = transformToPlayback(videoParams, false);
	analytics.web.videoPlaybackResumed({
		...product,
		...video
	});
};

export const videoCompleted = async (
	videoParams: PlaybackTrackingParams,
	contentParams: {
		locale: Locale;
		index: number | undefined;
		contentId?: string;
		courseId?: string;
	}
) => {
	const { content, course, analytics } = await getContentOrCourseAndAnalytics(contentParams);
	const product = content
		? transformContentDocumentToProduct(content, contentParams)
		: transformCourseDocumentToProduct(course, contentParams);

	const video = transformToPlayback(videoParams, false);
	analytics.web.videoPlaybackCompleted({
		...product,
		...video
	});
};

export const videoStoppedNoMembership = async (
	videoParams: PlaybackTrackingParams,
	contentParams: {
		locale: Locale;
		index: number | undefined;
		contentId?: string;
		courseId?: string;
	}
) => {
	const { content, course, analytics } = await getContentOrCourseAndAnalytics(contentParams);
	const product = content
		? transformContentDocumentToProduct(content, contentParams)
		: transformCourseDocumentToProduct(course, contentParams);

	const video = transformToPlayback(videoParams, false);
	analytics.web.videoPlaybackInterrupted({
		...product,
		video_playback: {
			...video.video_playback,
			method: 'NO_MEMBERSHIP'
		}
	});
};

const productPathRegex = /^\/product\/(\w+)$/;
const mediathekPathRegex = /^\/mediathek(?:\/(\w+))?(?:\/(\w+))?(?:\?(\w+=\w+))?$/;

export const useGenerateListIdFromRoute = (): string | undefined => {
	const router = useRouter();
	const currentPath = router.pathname;

	switch (true) {
		case currentPath === '/':
			return 'homepage';
		case productPathRegex.test(currentPath):
			return getProductListId(currentPath);
		case mediathekPathRegex.test(currentPath):
			return getMediathekListId(currentPath);
		default:
			return undefined;
	}
};

const getProductListId = (currentPath: string): string | undefined => {
	const match = currentPath.match(productPathRegex);
	return match ? ['product', match[1]].filter(Boolean).join('-') : undefined;
};

const getMediathekListId = (currentPath: string): string | undefined => {
	const match = currentPath.match(mediathekPathRegex);
	return match
		? ['mediathek', match[1], match[2]].filter(Boolean).join('-').replace('topics', 'specialty')
		: undefined;
};

export const trackAddToCalendar = async (
	contentId: string,
	params: { locale: Locale; index: number | undefined }
) => {
	const { content, analytics } = await getContentAndAnalytics(contentId);
	const product = transformContentDocumentToProduct(content, params);
	analytics.web.addedToCalendar({
		event_ub_id: content.externalId || content.parent?.externalId || null,
		...product
	});
};

export const trackCreateEventReminder = async (
	contentId: string,
	reminderType: WebTracking.EventReminderCreated['reminderType'],
	params: { locale: Locale; index: number | undefined }
) => {
	const { content, analytics } = await getContentAndAnalytics(contentId);
	const product = transformContentDocumentToProduct(content, params);
	analytics.web.eventReminderCreated({
		...product,
		reminderType
	});
};

const getContentForAnalyticsById = async (
	contentId: string
): Promise<Queries.GetContentForAnalyticsByIdQuery['content'] | undefined> => {
	if (contentId.length === 0) {
		return;
	}

	const graphqlClient = initializeApollo();
	const { data } = await graphqlClient.query<
		Queries.GetContentForAnalyticsByIdQuery,
		Queries.GetContentForAnalyticsByIdQueryVariables
	>({
		query: Queries.GetContentForAnalyticsById,
		variables: {
			contentId
		}
	});
	const content = data?.content;
	return content;
};

const getCourseForAnalyticsById = async (
	courseId: string
): Promise<Queries.GetCourseForAnalyticsByIdQuery['course'] | undefined> => {
	if (courseId.length === 0) {
		return;
	}

	const graphqlClient = initializeApollo();
	const { data } = await graphqlClient.query<
		Queries.GetCourseForAnalyticsByIdQuery,
		Queries.GetCourseForAnalyticsByIdQueryVariables
	>({
		query: Queries.GetCourseForAnalyticsById,
		variables: {
			courseId
		}
	});
	const content = data?.course;
	return content;
};

const getContentAndAnalytics = async (
	contentId: string
): Promise<{
	content: Queries.GetContentForAnalyticsByIdQuery['content'];
	analytics: SegmentAnalytics;
}> => {
	const [content, analytics] = await Promise.all([
		getContentForAnalyticsById(contentId),
		getAnalytics()
	]);
	if (!content || !analytics) {
		return Promise.reject(!content ? 'No content' : !analytics ? 'No analytics' : '');
	}
	return { content, analytics };
};

const getCourseAndAnalytics = async (
	courseId: string
): Promise<{
	course: Queries.GetCourseForAnalyticsByIdQuery['course'];
	analytics: SegmentAnalytics;
}> => {
	const [course, analytics] = await Promise.all([
		getCourseForAnalyticsById(courseId),
		getAnalytics()
	]);
	if (!course || !analytics) {
		return Promise.reject(!course ? 'No content' : !analytics ? 'No analytics' : '');
	}
	return { course, analytics };
};

const getContentOrCourseAndAnalytics = async (contentParams: {
	contentId?: string;
	courseId?: string;
}) => {
	const { contentId, courseId } = contentParams;
	if (contentId && courseId) {
		return Promise.reject('Only one of `contentId` and `courseId` can be passed');
	}

	if (contentId) {
		const { content, analytics } = await getContentAndAnalytics(contentId);
		return { content, course: undefined, analytics };
	} else if (courseId) {
		const { course, analytics } = await getCourseAndAnalytics(courseId);
		return { content: undefined, course, analytics };
	} else {
		return Promise.reject('One and only one of `contentId` and `courseId` can be passed');
	}
};
